import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Exhibition } from "../../../shared/types/sharedTypes";
import { OverviewBoxComponentProps } from "../../../shared/ui/components/OverviewBoxComponent";
import Pill from "../../../shared/ui/components/Pill";
import OrdersOverviewBoxesLayout from "../../../shared/ui/layouts/OrdersOverviewBoxesLayout";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnPriceFormattedCHF } from "../../../shared/utils/numbersManipulations";
import { fetchAllOrders } from "../../orders/api/fetchOrders";
import { ordersDataAtom } from "../../orders/atoms/orders-atoms";
import TableWithTitle from "../../orders/layouts/TableWithTitle";
import { QuickView } from "../../workshops/api/fetchWorkshops";
import { fetchAllExhibitions } from "../api/fetchExhibitions";
import {
  exhibitionsAtom,
  filteredExhibitionsAtom,
  selectedExhibitionAtom,
} from "../atoms/exhibitions-atoms";
import FiltersExhibitionsSelects from "../layouts/FiltersExhibitionsSelect";

export type ExhibitionExhibitor = {
  companyName: string;
  orderId: string;
  stands: boolean;
  conferences: boolean;
  workshops: boolean;
  careCenters: boolean;
  ads: boolean;
  betterVisibility: boolean;
  parking: boolean;
  quickView: QuickView;
};
export interface ExhibitionsListingProps {
  id: string | number;
  title: string;
  exhibition: Exhibition;
  standsCount: number;
  conferencesCount: number;
  workshopsCount: number;
  careCentersCount: number;
  year: string;
  status?: string;
  exhibitors: ExhibitionExhibitor[];
  totalRevenue: number;
  totalRevenueConfirmed: number;
}
const columnHelper = createColumnHelper<ExhibitionsListingProps>();

const ExhibitionsScreen = () => {
  const [exhibitions, setExhibitions] = useRecoilState(exhibitionsAtom);
  const setSelectedExhibition = useSetRecoilState(selectedExhibitionAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredExhibtions, setFilteredExhibitions] = useRecoilState(
    filteredExhibitionsAtom
  );

  const [orders, setOrders] = useRecoilState(ordersDataAtom);
  const ordersNumberForBulle = orders
    .filter((order) => order.exhibition.name.includes("Bulle"))
    .length.toString();
  const ordersNumberForMorges = orders
    .filter((order) => order.exhibition.name.includes("Morges"))
    .length.toString();
  const ordersNumberForAigle = orders
    .filter((order) => order.exhibition.name.includes("Aigle"))
    .length.toString();
  const ordersNumberForGeneve = orders
    .filter((order) => order.exhibition.name.includes("Genève"))
    .length.toString();

  useEffect(() => {
    fetchAllOrders()
      .then((orders) => {
        setOrders(orders);
      })
      .catch((err) => {
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  let navigate = useNavigate();
  const overViewBoxes: OverviewBoxComponentProps[] = [
    {
      title: "Commandes",
      label: "Bulle",
      value: ordersNumberForBulle,
      actionFunction: () => {
        // Navigate to 'salons/6980'
        navigate("/salons/6980");
      },
      primaryColor: "#D39A91",
      secondaryColor: "#EEDAD9",
      thirdColor: "#593937",
    },
    {
      title: "Commandes",
      label: "Morges",
      value: ordersNumberForMorges,
      actionFunction: () => {
        navigate("/salons/34");
      },
      primaryColor: "#89A8C9",
      secondaryColor: "#E1E7F4",
      thirdColor: "#445463",
    },
    {
      title: "Commandes",
      label: "Aigle",
      value: ordersNumberForAigle,
      actionFunction: () => {
        // alert("Salon pas encore ouvert");
        navigate("/salons/13515");
      },
      primaryColor: "#E3AB50",
      secondaryColor: "#FEE5B8",
      thirdColor: "#7D5E2C",
    },
    {
      title: "Commandes",
      label: "Genève",
      value: ordersNumberForGeneve,
      actionFunction: () => {
        // alert("Salon pas encore ouvert");
        navigate("/salons/14124");
      },
      primaryColor: "#87AC96",
      secondaryColor: "#D6E9BE",
      thirdColor: "#36453C",
    },
  ];

  const columns = [
    columnHelper.accessor("exhibition", {
      header: "Salon",
      cell: ({ getValue, row }) => (
        <Link
          // className="underline"
          to={`/salons/${row.original.id}`}
          onClick={() => {
            const selectedExhibition =
              exhibitions.find(
                (exhibition, index) => exhibition.id === row.original.id
              ) || null;

            selectedExhibition && setSelectedExhibition(selectedExhibition);

            // Scroll to top
            window.scrollTo(0, 0);
            // setSelectedOrderData(selectedOrder);
          }}
        >
          {/* {getValue()} */}
          <Pill
            label={getValue().name}
            bgColor={getValue().primaryColor}
            textColor={getValue().secondaryColor}
          />
        </Link>
      ),
    }),
    columnHelper.accessor("standsCount", {
      header: "Stands",
      cell: ({ getValue }) => <span className="font-bold">{getValue()}</span>,
    }),
    columnHelper.accessor("conferencesCount", {
      header: "Conférences",
      cell: ({ getValue }) => <span className="font-bold">{getValue()}</span>,
    }),
    columnHelper.accessor("workshopsCount", {
      header: "Ateliers",
      cell: ({ getValue }) => <span className="font-bold">{getValue()}</span>,
    }),
    columnHelper.accessor("careCentersCount", {
      header: "Espaces Soins",
      cell: ({ getValue }) => <span className="font-bold">{getValue()}</span>,
    }),
    columnHelper.accessor("totalRevenue", {
      header: "CA",
      cell: ({ getValue, cell }) => (
        <div className="flex flex-col items-center">
          <div className="flex flex-row gap-2 font-bold">
            <span>Validé : </span>
            <span>
              {returnPriceFormattedCHF(cell.row.original.totalRevenueConfirmed)}
            </span>
          </div>
          <div className="flex flex-row gap-2 text-lg">
            <span>Estimé : </span>
            {returnPriceFormattedCHF(getValue())}
          </div>
        </div>
      ),
    }),
  ];

  useEffect(() => {
    if (exhibitions.length > 0) return;
    console.log("loading exhibitions");
    setIsLoading(true);
    fetchAllExhibitions()
      .then((data) => setExhibitions(data))
      .catch((err) => {
        console.log(err);
        if (err.code.includes("jwt")) {
          console.log(err);
          localStorage.removeItem("token-dashboard");
          window.location.replace("/");
        }
      })
      .finally(() => {
        console.log("done loading exhibitions");
        setIsLoading(false);
      });
  }, []);
  return (
    <PageLayout>
      <OrdersOverviewBoxesLayout
        customGlobalLabel="Commandes"
        title="Salons à venir"
      />
      <TableWithTitle
        isLoading={isLoading}
        title="Liste des salons"
        tableData={
          filteredExhibtions !== null ? filteredExhibtions : exhibitions
        }
        tableColumns={columns}
        filtersComponents={<FiltersExhibitionsSelects />}
      />
      <p className="text-right text-[#cbd5e1]">v. 2.0.20</p>
    </PageLayout>
  );
};

export default ExhibitionsScreen;
